.companiesHero {
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 2;
        width: 80%;
        color: white;
        margin: 60px 0 40px 0;

        .taglines {
            margin-bottom: 90px;

            .main {
                display: flex;
                flex-direction: column;
                color: #023341;
                line-height: 1.5em;
                margin: 0;
                font-size: 80px;
                font-weight: 700;
                width: 100%;

                .so {
                    font-size: 120px;
                }

                @media (max-width:1330px) {
                    font-size: 70px;
                    .so {
                        font-size: 110px;
                    }
                }

                @media (max-width:1150px) {
                    font-size: 60px;
                    .so {
                        font-size: 100px;
                    }
                }

                @media (max-width:1000px) {
                    font-size: 50px;
                    .so {
                        font-size: 90px;
                    }
                }
                
                @media (max-width:850px) {
                    align-items: flex-start;
                    text-align: left;
                    margin: 20px 0;
                    .so {
                        font-size: 80px;
                    }
                }

                @media (max-width:450px) {
                    font-size: 40px;
                    .so {
                        font-size: 60px;
                    }
                }
            }
            @media (max-width:500px) {
                margin-bottom: 40px;
            }
        }

        @media (max-width:850px) {
            width: 90%;
        }

        .scrollIndicator {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;

            p {
                position: relative;
                z-index: 1;
                color: #f04902;
                font-size: 26px;
                font-weight: bold;
                text-align: center;

                @media (max-width:850px) {
                    font-size: 18px;
                }
            }

            .arrow {
                display: inline-block;
                position: relative;
                width: 8px;
                height: 80px;
                background-color: #f04902;
                border-radius: 10px;

                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%) rotate(45deg);
                    width: 30px;
                    height: 30px;
                    border-right: 8px solid #f04902;
                    border-bottom: 8px solid #f04902;
                    @media (max-width:850px) {
                        border-right: 5px solid #f04902;
                        border-bottom: 5px solid #f04902;
                        width: 20px;
                        height: 20px;
                    }
                }

                @media (max-width:850px) {
                    width: 5px;
                    height: 50px;
                }
            }
        }

        @media (max-width:500px) {
            margin: 0;
        }
    }
}