.techstacks-how-it-works {
    flex: 1;
}

.techstacks-how-it-works-content-container {
    width: 80%;
    display: flex;
}

.techstacks-how-it-works-left-content {
    width: 55%;
    background-color: #035E70;
    color: white;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.techstacks-how-it-works-left-content-container {
    width: 60%;
    margin: 50px 0 0 90px;
    height: 180px;
}

.techstacks-how-it-works-title {
    color: white;
    text-align: left;
    font-size: 45px;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.techstacks-how-it-works-description {
    text-align: left;
    color: white;
    font-size: 18px;
    font-weight: normal;
    padding: 0;
    margin: 10px 0 0 0;
}

.techstacks-how-it-works-step-box {
    background-color: #035E70;
    width: 55%;
    position: absolute;
    z-index: -1;
    height: 170px;
    bottom: 0;
}

.techstacks-how-it-works-step-box button {
    border: none;
    background: none;
    position: absolute;
    font-size: 40px;
    bottom: 60px;
    right: 60px;
    width: 50px; /* Adjust icon size as needed */
    height: 50px;
    color: #000;
}

@media (max-width: 1000px) {
    .techstacks-how-it-works-left-content {
        width: 65%;
        height: 200px;
    }
    
    .techstacks-how-it-works-left-content-container {
        width: 60%;
        margin: 50px 0 0 0px;
        height: 220px;
    }

    .techstacks-how-it-works-step-box {
        width: 70%;
        height: 150px;
    }

    .techstacks-how-it-works-step-box button {
        font-size: 35px;
        bottom: 35px;
        right: 75px;
    }
}

@media (max-width: 700px) {
    .techstacks-how-it-works-left-content {
        width: 85%;
        height: 200px;
    }
    
    .techstacks-how-it-works-left-content-container {
        width: 60%;
        margin: 50px 0 0 0px;
        height: 220px;
    }

    .techstacks-how-it-works-title { 
        font-size: 35px;
    }

    .techstacks-how-it-works-description {
        font-size: 18px;
    }

    .techstacks-how-it-works-step-box {
        width: 90%;
        height: 130px;
    }

    .techstacks-how-it-works-step-box button {
        font-size: 35px;
        bottom: 35px;
        right: 50px;
    }
}

@media (max-width: 500px) {
    .techstacks-how-it-works-left-content {
        width: 85%;
        height: 200px;
    }
    
    .techstacks-how-it-works-left-content-container {
        width: 90%;
        margin: 50px 0 0 0px;
        height: 220px;
    }

    .techstacks-how-it-works-title { 
        font-size: 35px;
    }

    .techstacks-how-it-works-description {
        font-size: 18px;
    }

    .techstacks-how-it-works-step-box {
        width: 100%;
        height: 130px;
    }

    .techstacks-how-it-works-step-box button {
        font-size: 35px;
        bottom: 35px;
        right: 50px;
    }
}