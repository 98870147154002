.career-hero-stepdown {
    flex: 1;
}

.career-hero-stepdown-content-container {
    width: 80%;
    display: flex;
}

.career-hero-stepdown-left-content {
    width: 55%;
    background-color: #035E70;
    color: white;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.career-hero-stepdown-left-content-container {
    width: 60%;
    margin: 80px 0 0 90px;
    height: 180px;
}

.career-hero-stepdown-title {
    color: #F5BDA2;
    text-align: left;
    font-size: 45px;
    font-weight: bold;
    padding: 0;
    margin: 50px 0 0 0;
}

.career-hero-stepdown-description {
    text-align: left;
    color: white;
    font-size: 18px;
    font-weight: normal;
    padding: 0;
    margin: 10px 0 0 0;
}

.career-hero-stepdown-step-box {
    background-color: #035E70;
    width: 55%;
    position: absolute;
    z-index: -1;
    height: 170px;
    bottom: 0;
}

.career-hero-stepdown-step-box button {
    border: none;
    background: none;
    position: absolute;
    font-size: 40px;
    bottom: 60px;
    right: 60px;
    width: 50px; /* Adjust icon size as needed */
    height: 50px;
    color: #000;
}

.career-hero-submit-button {
    position: absolute;
    bottom: 50px;
    right: 10%;
    width: 30%;
    height: 53.53px;
    background-color: #F04902;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    margin: 10px 0;
}

.career-hero-submit-button:hover {
    background-color: #fff;
    border: solid 2px #F04902;
    color: #F04902;
    transition: 0.3s;
}

@media (max-width: 1000px) {
    .career-hero-stepdown-left-content {
        width: 65%;
        height: 200px;
    }
    
    .career-hero-stepdown-left-content-container {
        width: 60%;
        margin: 50px 0 0 0px;
        height: 220px;
    }

    .career-hero-stepdown-step-box {
        width: 70%;
        height: 150px;
    }

    .career-hero-stepdown-step-box button {
        font-size: 35px;
        bottom: 35px;
        right: 75px;
    }

    .career-hero-submit-button {
        right: 2.5%;
        width: 25%;
    }
}

@media (max-width: 700px) {
    .career-hero-stepdown-left-content {
        width: 85%;
        height: 200px;
    }
    
    .career-hero-stepdown-left-content-container {
        width: 60%;
        margin: 50px 0 0 0px;
        height: 220px;
    }

    .career-hero-stepdown-title { 
        font-size: 35px;
    }

    .career-hero-stepdown-description {
        font-size: 18px;
    }

    .career-hero-stepdown-step-box {
        width: 90%;
        height: 130px;
    }

    .career-hero-stepdown-step-box button {
        font-size: 35px;
        bottom: 35px;
        right: 50px;
    }

    .career-hero-submit-button {
        right: 10%;
        bottom: -10px;
        width: 22%;
        border-radius: 0;
        height: 130px;
    }
}

@media (max-width: 500px) {
    .career-hero-stepdown-left-content {
        width: 85%;
        height: 200px;
    }
    
    .career-hero-stepdown-left-content-container {
        width: 90%;
        margin: 50px 0 0 0px;
        height: 220px;
    }

    .career-hero-stepdown-title { 
        font-size: 30px;
    }

    .career-hero-stepdown-description {
        font-size: 18px;
    }

    .career-hero-stepdown-step-box {
        width: 100%;
        height: 130px;
    }

    .career-hero-stepdown-step-box button {
        font-size: 35px;
        bottom: 35px;
        right: 50px;
    }

    .career-hero-submit-button {
        right: 0%;
        bottom: -10px;
        width: 32%;
        border-radius: 0;
        height: 130px;
    }
}