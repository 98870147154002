.readytotalkSection {
    background: url("../../../images/Background.png") no-repeat center center/cover;
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: beige;
    padding: 40px 0;

    .title {
        text-align: left;
        width: 80%;

        h2 {
            color: #d94f2b;
        }
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 80%;
        gap: 40px;
        margin: 30px 0;

        .calendar {
            background-color: #fff;
            width: 65%;
            text-align: left;
            border-radius: 20px;
            .title {
                color: #265d6e;
                font-size: 2.6em;
                text-align: left;
                margin: 30px 0 0 30px;
            }

            .subtitle {
                color: #265d6e;
                font-size: 1.2em;
                font-weight: bold;
                margin: 30px 0 0 30px;
            }

            @media (max-width: 950px) {
                width: 90%;
            }

            @media (max-width: 500px) {
                width: 100%;
            }
        }

        .enquire {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 35%;
            background-color: #e7e5e5;
            border-radius: 20px;
            padding: 20px;

            .title {
                color: #265d6e;
                font-size: 3em;
                text-align: center;
            }

            .subtitle {
                color: #265d6e;
                font-size: 1.2em;
                font-weight: bold;
                width: 70%;
            }

            .form {
                height: 100px;
                width: 100%;
                margin: 40px 0 20px 0;

                .formContainer {
                    background-color: #fff;
                    border-radius: 10px;
                    padding: 5px;
                    margin: 0;

                    input {
                        height: 50px;
                        width: 70%;
                        border: none;
                        font-size: 20px;

                        &:focus {
                            outline: none;
                        }
                    }

                    button {
                        width: 27%;
                        height: 50px;
                        margin: 0;
                        padding: 0;
                        background-color: #f04902;
                        color: white;
                        border: none;
                        border-radius: 10px;
                        font-size: 16px;
                        cursor: pointer;
                        text-align: center;

                        &:hover {
                            border: #f04902 solid 2px;
                            background-color: #fff;
                            color: #f04902;
                            transition: 0.3s;
                        }
                    }
                }
            }


            .success {
                font-weight: bold;
                color: #265d6e;
                .visible {
                    opacity: 1;
                }
                &:not(.visible) {
                    opacity: 0;
                }
            }

            .error {
                transition: opacity 0.5s ease;
                color: rgb(195, 0, 0);
                font-weight: bold;
                .visible {
                    opacity: 1;
                }
                &:not(.visible) {
                    opacity: 0;
                }
            }

            @media (max-width: 950px) {
                width: 80%;
            }
        }

        @media (max-width: 950px) {
            flex-direction: column;
            align-items: center;
        }
    }
}
