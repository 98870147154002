.howwedoitSection {
    position: relative;
    height: auto;
    background: url("../../../images/howwedoit_background.png")
        no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 85%;
        .title {
            text-align: left;
            width: 100%;
    
            h2 {
                color: #F5BDA2;
            }
        }

        .step {
            display: flex;
            flex-direction: row;
            margin: 20px 0;
            width: 100%;
            
            .tagline {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                color: #fff;
                width: 60%;
                height: 200px;
                gap:40px;

                h1 {
                    font-size: 8em;
                    border-right: #F5BDA2 solid 1px;
                    width: 30%;
                    
                    @media (max-width:800px) {
                        font-size: 6em;
                    }

                    @media (max-width:550px) {
                        font-size: 5em;
                    }

                    @media (max-width:400px) {
                        font-size: 4em;
                    }
                }

                p {
                    font-size: 2.3em;
                    font-weight: bold;
                    text-align: left;
                    width: 70%;

                    @media (max-width:800px) {
                        font-size: 1.9em;
                    }

                    @media (max-width:400px) {
                        font-size: 1.3em;
                    }
                }


                @media (max-width:500px) {
                    width: 100%;
                    height: 130px;
                    gap: 10px;
                }
            }
            
            .details {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: left;
                color: #fff;
                font-size: 1.3em;
                width: 40%;

                @media (max-width:500px) {
                    width: 100%;
                }
            }

            @media (max-width:500px) {
                flex-direction: column;
            }
        }
    }
}
