.techstacks-hero {
    position: relative;
    height: 950px;
    /* Fixed height of the hero section */
    background: url('../../../images/coding-man.jpg') no-repeat center center/cover;
    /* Adjust the path as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.techstacks-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #023341;
    opacity: 0.8;
    /* Adjust opacity to make it look like a pass-through */
    mix-blend-mode: multiply;
    /* Blend mode */
}

.techstacks-hero-top-spacer {
    height: 200px;
}

.techstacks-hero-content {
    position: relative;
    z-index: 2;
    display: flex;
    width: 80%;
    /* Make content div full width */
    color: white;
    /* Ensuring text is visible on dark background */
    height: 700px;
}

.techstacks-hero-bottom-content {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    /* Make content div full width */
    height: 300px;
}

.techstacks-hero-left-container {
    width: 80%;
    text-align: left;
    /* Aligning text to the left */
}

.techstacks-hero-right-container {
    width: 20%;
}

.techstacks-hero-taglines {
    margin-bottom: 40px;
    /* Space between taglines and buttons */
}

.techstacks-hero-main-tagline {
    color: white;
    /* Text color */
    line-height: 0.8;
    /* Ensure proper line spacing */
}

.techstacks-hero-main-tagline span:nth-child(1) {
    font-size: 50px;
    /* Font size for "Your" */
    font-weight: 200;
}

.techstacks-hero-main-tagline span:nth-child(3) {
    font-size: 80px;
    /* Font size for "tailored" */
    font-weight: 200;
}

.techstacks-hero-main-tagline span:nth-child(5) {
    font-size: 90px;
    /* Font size for "on-demand" */
    font-weight: bold;
    /* Bold style */
}

.techstacks-hero-main-tagline span.orange-dot {
    color: #F04902;
    font-size: 90px;
    line-height: 20px;
}

.techstacks-hero-sub-tagline {
    font-size: 20px;
    margin: 50px 0 0 25px;
    font-weight: normal;
    width: 60%;
}

.techstacks-hero-buttons-container {
    bottom: 0;
    width: 100%;
    /* Full width to center buttons below content */
    display: flex;
    justify-content: center;
}

.techstacks-hero-buttons {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    /* Centering the buttons container horizontally */
}

.techstacks-hero-button {
    background: none;
    height: 80px;
    width: 80px;
    background-color: "clear";
    /* Example button color */
    color: white;
    border: #fff solid 4px;
    border-radius: 40px;
    cursor: pointer;
    position: relative;
    font-size: 20px;
    transition: 0.3s;
}

.techstacks-hero-button-text {
    overflow-wrap: break-word;
    inline-size: 100px;
    position: absolute;
    color: #fff;
    font-weight: bold;
    margin: 40px 0 0 0;
    left: -25px;
    width: 120px;
    transition: transform 0.3s ease;
    transform: translateY(0);
}

.techstacks-hero-button-text.active {
    transform: translateY(30px);
}

.techstacks-hero-button:hover {
    box-shadow: inset 0 -3em 3em #5BC0EC, #5BC0EC 0 0px 15px;
    transition: 0.3s;
}

.techstacks-hero-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 80px;
    /* Full width to cover the half circle */
    height: 80px;
    /* Full height to cover the half circle */
    border-radius: 50%;
    /* Maintain the circular shape */
    border-top: 5px solid #5BC0EC;
    /* Top border */
    /* border-right: 2px solid blue;  Right border */
    transform: translateX(-50%) translateY(-7%) rotate(45deg);
    /* Position and rotate */
    transform-origin: center;
    /* Origin of rotation */
}

.techstacks-hero-button-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 100px;
}

.techstacks-hero-button-info-container {
    opacity: 0;
    transition: 0.3s;
    color: #C8E9FD;
    font-size: 18px;
    font-weight: bold;
    width: 80%;
    transition: transform 0.3s ease;
    transform: translateY(-30px);
}

.techstacks-hero-button-info-container.visible {
    opacity: 1;
    transition: 0.3s;
    transform: translateY(0);
}

@media (max-width: 800px) {
    .techstacks-hero {
        height: 1140px;
    }
    .techstacks-hero-button-info-container.visible {
        opacity: 1;
        transition: 0.3s;
        transform: translateY(-20px);
    }
}

@media (max-width: 700px) {
    .techstacks-hero {
        height: 1040px;
    }

    .techstacks-hero-left-container {
        width: 100%;
    }

    .techstacks-hero-right-container {
        width: 0%;
    }

    .techstacks-hero-main-tagline {
        color: white;
        /* Text color */
        line-height: 0.8;
        /* Ensure proper line spacing */
    }

    .techstacks-hero-main-tagline span:nth-child(1) {
        font-size: 30px;
        /* Font size for "Your" */
        font-weight: 200;
    }

    .techstacks-hero-main-tagline span:nth-child(3) {
        font-size: 50px;
        /* Font size for "tailored" */
        font-weight: 200;
    }

    .techstacks-hero-main-tagline span:nth-child(5) {
        font-size: 55px;
        font-weight: bold;
    }
    
    .techstacks-hero-sub-tagline {
        font-size: 20px;
        margin: 50px 0 0 0px;
        font-weight: normal;
        width: 100%;
    }

    .techstacks-hero-buttons {
        width: 100%;
    }

    .techstacks-hero-button {
        height: 60px;
        width: 60px;
        border-radius: 30px;
        font-size: 15px;
    }

    .techstacks-hero-button::before {
        width: 60px;
        height: 60px;
        transform: translateX(-50%) translateY(-8%) rotate(45deg);
    }

    .techstacks-hero-button-text {
        font-size: 14px;
        inline-size: 100px;
        font-weight: bold;
        margin: 40px 0 0 0;
        left: -33px;
        width: 120px;
        transition: transform 0.3s ease;
        transform: translateY(0);
    }

    .techstacks-hero-button-info-container.visible {
        opacity: 1;
        transition: 0.3s;
        transform: translateY(0px);
    }
}

@media (max-width: 500px) {
    .techstacks-hero-button-text {
        display: none;
    }

    .techstacks-hero-button-info-container.visible {
        opacity: 1;
        transition: 0.3s;
        transform: translateY(-80px);
    }
}