.techstacks-options-section {
    height: 1300px;
    background-color: #035E70;
    background-size: cover;
    padding: 0px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.techstacks-options-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70%;
}

.techstacks-options-title {
    text-align: left;
    color: #fff;
    width: 80%;
}

.techstacks-options-sub-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 48%;
}

.techstacks-options-sub-form-title {
    color: #fff;
    text-align: left;
    width: 100%;
}

.techstacks-options-input-container {
    width: 100%;
    margin: 10px 0 0 0;
}

.techstacks-options-input-container label {
    text-align: left;
    display: block;
    color: #fff;
}

.techstacks-options-input-container input {
    margin: 10px 0;
    padding: 0 5px;
    height: 40px;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    width: calc(100% - 10px);
}

.techstacks-options-input-container textarea {
    margin: 10px 0;
    padding: 5px;
    max-height: 130px;
    height: 130px;
    border: none;
    border-radius: 3px;
    max-width: calc(100% - 10px);
    width: calc(100% - 10px);
}

.techstacks-options-sub-form button {
    width: 100%;
    height: 53.53px;
    background-color: #F04902;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    margin: 10px 0;
}

.techstacks-options-sub-form button:hover {
    background-color: #fff;
    border: solid 2px #F04902;
    color: #F04902;
    transition: 0.3s;
}

@media (max-width: 800px) {
    .techstacks-options-section {
        height: 1500px;
    }

    .techstacks-options-content-container {
        width: 100%;
    }

    .techstacks-options-sub-form {
        width: 63%;
    }
}

@media (max-width: 600px) {
    .techstacks-options-section {
        height: 1600px;
    }

    .techstacks-options-content-container {
        width: 100%;
    }

    .techstacks-options-sub-form {
        width: 90%;
    }
}