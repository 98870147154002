.companiesPage {
    position: relative;
}

// .companiesInitialOverlay {
//     position: absolute;
//     background-color: #ff7f50;
//     height: 100vh;
//     width: 100vw;
//     top:0;
// }