.joblisting-top-spacer {
    background: #035E70;
    height: 100px;
}

.joblisting-section {
    background-color: #035E70;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.joblisting-section-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 80%;
}

.joblisting-item {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(50% - 20px); /* Two items per row with gap */
    max-width: calc(50% - 20px);
    box-sizing: border-box;
    transition: 0.3s;
}

.joblisting-item:hover {
    background-color: #1F4A58;
    color: #87A4AC;
    transition: 0.3s;
    cursor: pointer;
}

.joblisting-item:hover .rounded-circle{
    background-color: #1A3E49;
    transition: 0.3s;
}

.joblisting-item:hover .btn{
    background-color: #255667;
    transition: 0.3s;
}

.rounded-circle{
    background-color: #ebebeb;
    width: 60px;
    height: 60px;
    padding: 5px;
    border-radius: 35px;
    object-fit: contain;
}

.joblisting-item-top {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.aside-1 {
    text-align: left;
    height: auto;
    width: 60%;
    margin: 0 0 0 20px;
}

.aside-1 h3 {
    margin: 0 0 5px 0;
}

.country{
    color: #035E70;
    font-weight: normal;
    margin: 5px 0 0 0;
}

.aside-1 img {
    width: 100%;
}

.btn{
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: #F2F9FE;
    color: #75BEE8;
    margin: 5px 10px;
}

@media all and (min-width: 600px) {
    .aside {
        flex: 1;
    }
}

@media (max-width: 1400px) {
    .joblisting-top-spacer {
        height: 150px;
    }
}

@media (max-width: 1000px) {
    .joblisting-top-spacer {
        height: 180px;
    }
}

@media (max-width: 800px) {
    .joblisting-top-spacer {
        height: 250px;
    }

    .joblisting-section-container {
        flex-direction: column;
        width: 90%;
    }

    .joblisting-item {
        max-width: 100%;
    }
}

@media (max-width: 730px) {
    .joblisting-top-spacer {
        height: 350px;
    }
}

@media (max-width: 700px) {
    .joblisting-top-spacer {
        height: 200px;
    }
}

@media (max-width: 600px) {
    .joblisting-top-spacer {
        height: 270px;
    }

    .techstacks-options-section {
        height: 1600px;
    }

    .techstacks-options-content-container {
        width: 100%;
    }

    .techstacks-options-sub-form {
        width: 90%;
    }
}

@media (max-width: 400px) {
    .joblisting-top-spacer {
        height: 350px;
    }
}