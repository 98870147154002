.footer-section {
    position: relative;
    height: 600px;
    background: url('../../images/footer.png') no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 85%;
}

.footer-left-content, .footer-right-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-left-content {
    width: 45%;
    height: 100%;
    position: relative;
}

.footer-logo {
    position: absolute;
    top: 60px;
    left: 5px;
    width:250px;
}

.footer-copyright {
    color:#fff;
    text-align: left;
    position: absolute;
    bottom: 60px;
    left: 20px;
}

.footer-right-content {
    width: 55%;
    height: 100%;
}

.footer-right-comma {
    font-size: 100px;
    width: 100px;
    height: 100%;
}

.footer-right-quote-box {
    height: 430px;
    width: 520px;
    margin-right: 40px;
    background-color: #035E70;
    color: #fff;
    font-size: 45px;
    text-align: left;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-right-quote-box p {
    width: 80%;
}

.footer-right-quote-box .orange-dot {
    font-size: 75px;
    line-height: 45px;
}

@media (max-width: 1000px) {
    .footer-right-quote-box {
        font-size: 40px;
    }
}

@media (max-width: 850px) {
    .footer-right-comma {
        font-size: 80px;
    }
    
    .footer-right-quote-box {
        font-size:35px;
    }
}

@media (max-width: 700px) {
    .footer-right-comma {
        font-size: 60px;
    }
    
    .footer-right-quote-box {
        font-size:30px;
    }
}

@media (max-width: 700px) {
    .footer-section {
        height: 800px;
    }
    .footer-content-container {
        flex-direction: column;
    }
    .footer-left-content {
        width: 100%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .footer-right-content {
        width: 100%;
    }
    .footer-right-comma {
        font-size: 60px;
    }
    
    .footer-right-quote-box {
        font-size:30px;
    }

    .footer-logo {
        position: static;
        width:250px;
    }
    
    .footer-copyright {
        text-align: left;
        position:static;
    }
}