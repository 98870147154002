.whatweofferSection {
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0 100px 0;

    .title {
        text-align: left;
        width: 80%;

        h2 {
            color: #F04902;
        }
    }

    .container {
        display: flex;
        flex-direction: row;
        width: 80%;
        .left {
            width: 40%;
            text-align: left;
            h2 {
                color: #265D6E;
                font-size: 2.5em;
            }
            p {
                font-weight: 200;
                font-size: 1.2em;
            }

            @media (max-width:800px) {
                width: 60%;
            }
        }
        
        .right {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            width: 60%;
            .line {
                width: 70%;
                background-color: rgb(238, 238, 238);
                height: 2px;
            }
            .bubble {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 50px;
                border: #ffffff33 solid 2px;
                border-radius: 25px;
                background-color: #0a73932d;
                color: #fff;
                font-weight: bold;
                font-size: 1.4em;
            }

            @media (max-width:800px) {
                width: 40%;
            }
        }
    }
}