/* HERO */

/* Hero.css */
.hero {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.8;
    /* Adjust opacity to make it look like a pass-through */
    mix-blend-mode: multiply;
    /* Blend mode */
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 80%;
    /* Make content div full width */
    color: white;
    /* Ensuring text is visible on dark background */
    height: 700px;

    @media (max-width:500px) {
        width: 95%;
    }
}

.taglines {
    margin-bottom: 40px;
}

.main {
    color: #023341;
    line-height: 1;
    font-size: 5em;
    font-weight: 700;

    @media (max-width:1000px) {
        font-size: 4em;
    }

    @media (max-width:800px) {
        font-size: 3em;
    }
    
    @media (max-width:500px) {
        font-size: 2.5em;
    }
}

.orangeDot {
    color: #F04902;
}

.button {
    position: relative;
    border-radius: 27px;
    width: 300px;

    button {
        position: relative;
        z-index: 1;
        width: 300px;
        height: 53.53px;
        background-color: #F04902;
        color: white;
        border: none;
        border-radius: 27px;
        font-size: 16px;
        cursor: pointer;
        text-align: center;

        &:hover {
            background-color: #fff;
            border: solid 2px #F04902;
            color: #F04902;
            transition: 0.3s;

            + .dottedLine {
                width: 100vw;
            }
        }
        
        @media (max-width:500px) {
            font-size: 15px;
            width: 270px;
        }
    }

    .dottedLine {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 0;
        border-top: 2px dotted #ff7f50; /* Match the color to your design */
        transition: width 0.5s ease-in-out;
        z-index: 0;
    }
}

button:hover .dottedLine {
    width: 100vw;
}

.homeInitialOverlay {
    position: absolute;
    background-color: #023341;
    height: 100vh;
    width: 100vw;
}

.homeExitOverlay {
    position: absolute;
    background-color: #ff7f50;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
}