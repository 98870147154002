.headerMainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .callAction {
        flex: 1;
    }

    .logo {
        flex: 1;
        img {
            height: auto;
            width: auto;
        }

        @media (max-width: 800px) {
            margin: 0 0 0 40px;
        }

        @media (max-width: 500px) {
            margin: 0 0 0 20px;
        }
    }

    .navContainer {
        flex: none;
        border: 0.5px solid #000;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        width: 306px;
        padding: 0 15px;
        height: 49.37px;

        nav {
            display: flex;
            align-items: center;
            width: 100%;

            a {
                flex: 1;
                font-size: 12px;
                font-weight: bold;
                text-decoration: none;
                color: #000;
                padding: 10px 15px;

                .active {
                    flex: 1;
                    font-size: 12px;
                    font-weight: bold;
                    text-decoration: none;
                    color: #ffba9d;
                    padding: 10px 15px;
                }

                &::selection {
                    text-decoration: none;
                    color: #ffba9d;
                }

                &:hover {
                    color: #ffba9d;
                    text-decoration: none;
                }
            }
        }

        @media (max-width: 800px) {
            display: none;
        }
    }

    .callAction {
        button {
            width: 178.33px;
            height: 53.53px;
            background-color: #f04902;
            color: white;
            border: none;
            border-radius: 27px;
            font-size: 16px;
            cursor: pointer;
            text-align: center;

            .scheduleIcon {
                display: none;

                @media (max-width: 500px) {
                    display: block;
                }
            }

            .scheduleText {
                display: block;
                @media (max-width: 500px) {
                    display: none;
                }
            }

            &:hover {
                background-color: #fff;
                border: solid 2px #f04902;
                color: #f04902;
                transition: 0.3s;
            }

            @media (max-width: 500px) {
                width: 73.53px;
            }
        }

        @media (max-width: 380px) {
            display: none;
        }
    }
}

.mobileNavContainer {
    flex: none;
    border: 0.5px solid #fff;
    border-radius: 30px;
    display: none;
    justify-content: center;
    height: 49.37px;
    margin: 20px 5%;
    width: 90%;

    nav {
        display: flex;
        align-items: center;
        width: 100%;

        a {
            flex: 1;
            font-size: 12px;
            font-weight: bold;
            text-decoration: none;
            color: #000;
            padding: 10px 15px;

            .active {
                flex: 1;
                font-size: 12px;
                font-weight: bold;
                text-decoration: none;
                color: #ffba9d;
                padding: 10px 15px;
            }

            &::selection {
                text-decoration: none;
                color: #ffba9d;
            }

            &:hover {
                color: #ffba9d;
                text-decoration: none;
            }
        }
    }

    @media (max-width: 800px) {
        display: flex;
    }
}

.header {
    position: relative;
    width: 100%;
    padding: 40px 0px 20px 0px;
    background: "clear";

    .mobile-nav-container {
        border: #000 solid 0.5px;

        nav a {
            color: #000;
        }
    }

    @media (max-width: 800px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.overlayHeader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    padding: 40px 0px 20px 0px;
    background: "clear";

    .navContainer {
        border: #fff solid 0.5px;

        nav a {
            color: #fff;
        }
    }

    .mobileNavContainer {
        border: #fff solid 0.5px;

        nav a {
            color: #fff;
        }
    }

    @media (max-width: 800px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
