.techstacksdropdown-container {
    position: relative;
    margin: 60px 0 30px 0;
    width: 500px;
}


.techstacksdropdown-input-container {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
}

.techstacksdropdown-input {
    border: none;
    outline: none;
    flex-grow: 1;
    font-size: 16px;
    height: 35px;
}

.techstacksdropdown-input:hover {
    cursor: pointer;
}

.techstacksdropdown-input-option {
    background: #e0e0e0;
    border-radius: 2px;
    padding: 2px 5px;
    margin: 2px;
    display: flex;
    align-items: center;
}

.techstacksdropdown-input-option-remove-button {
    border: none;
    background: none;
    margin-left: 5px;
    cursor: pointer;
    color: #000;
}

.techstacksdropdown-input-drop-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
}

.techstacksdropdown-input-drop-menu-item {
    padding: 5px;
    cursor: pointer;
}

.techstacksdropdown-input-drop-menu-item:hover {
    background: #f0f0f0;
}


@media (max-width: 510px) {
    .techstacksdropdown-container {
        position: relative;
        margin: 60px 0 30px 0;
        width: 90%;
    }
}