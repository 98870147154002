.techstacksmainoptions-container {
  display: grid;
  width: 800px;
  padding: 10px;
  height: auto;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.techstacksmainoptions-box {
  background-color: #fff;
  width: 125px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.techstacksmainoptions-box.selected {
  background-color: #F04902;
  color: #fff;
}

.techstacksmainoptions-box:hover {
  background-color: #f0f0f0;
  box-shadow: #499ab3 0px 0px 16px;
}

.techstacksmainoptions-box.selected:hover {
  background-color: #d54000;
  box-shadow: #ebebeb 0px 0px 16px;
}

.techstacksmainoptions-box img {
  width: 40px;
  height: 40px;
  padding: 25px;
  object-fit: contain;
}

.techstacksmainoptions-name {
  padding: 5px;
  font-size: 14px;
}

@media (max-width: 800px) {
  .techstacksmainoptions-container {
    width: 100%;
    padding: 0px;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 600px) {
  .techstacksmainoptions-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .techstacksmainoptions-box {
    width: 105px;
    height: 120px;
  }

  .techstacksmainoptions-container {
    grid-template-columns: repeat(3, 1fr);
  }
}