.career-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #035E70;
}

.career-details-content {
    padding: 20px;
    width: 70%;
}

.career-details-content h2 {
    color: #F5BDA2;
    margin-bottom: 15px;
    text-align: left;
}

.career-details-content ul {
    text-align: left;
    color: #fff;
    width: 80%;
    padding: 0;
}

.career-details-content li {
    margin-bottom: 1px;
    line-height: 1.6;
}

.career-details-interested-title {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin: 45px 0 10px 0;
    text-align: center;
}

.career-details-submit-button {
    width: 30%;
    height: 53.53px;
    background-color: #F04902;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    margin: 10px 0;
}

.career-details-submit-button:hover {
    background-color: #fff;
    border: solid 2px #F04902;
    color: #F04902;
    transition: 0.3s;
}

@media (max-width: 700px) {
    .career-details-content ul{
        width: 100%;
    }

    .career-details-submit-button {
        width: 80%;
    }
}