.partnersSection {
    position: relative;
    height: auto;
    background: #035E70;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 85%;
        .title {
            text-align: left;
            width: 100%;
            margin: 0 0 40px 0;
    
            h1 {
                font-size: 2em;
                color: #FFAC90;
            }
        }
        .companies {
            display: flex;
            flex-direction: row;
            height: 70px;
            gap: 40px;

            .company {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 20px 0;
                width: 100%;
                
                img {
                    height: 60px;
                    width: 140px;
                    margin: 10px;
                    object-fit: contain;
                }

                .stagger {
                    height: 60px;
                    width: 140px;
                    margin: 10px;
                    @media (max-width:600px) {
                        display:none;
                    }
                }
            }

            @media (max-width:600px) {
                flex-direction: column;
                height: auto;
                gap:0;
                margin: 0;
            }
        }
    }
}
