.whychooseusSection {
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 85%;
        .title {
            text-align: center;
            width: 100%;
    
            h2 {
                font-size: 2em;
                color: #d94f2b;
            }
        }
        .items {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap:20px;
            .item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 20px 0;
                background-color: #bbc7c67a;
                border-radius: 20px;
                width: 25%;
                
                .tagline {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    color: #000;
                    width: 80%;
                    height: 130px;
                    gap:40px;
    
                    p {
                        font-size: 2.3em;
                        font-weight: bold;
                        text-align: left;
                        width: 100%;
    
                        @media (max-width:800px) {
                            font-size: 1.9em;
                        }
                    }
                }
                
                .details {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: left;
                    color: #000;
                    font-size: 1.3em;
                    width: 80%;
                }

                @media (max-width:1440px) {
                    width: 30%;
                }
                
                @media (max-width:900px) {
                    width: 80%;
                    padding: 30px 0;
                }
                
                @media (max-width:500px) {
                    width: 95%;
                }
            }
            @media (max-width:900px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
